import React, { useState, useEffect, useRef } from "react";

export default function CustomTypography({
    html,
    text,
    expandable,
    maxLines = 3,
    lineHeight = 1.5, // Default line height
    ...props
}) {
    const textRef = useRef(null);
    const [showMore, setShowMore] = useState(expandable ? false : true);
    const [showBtn, setShowBtn] = useState(false);
    const [isOldSafari, setIsOldSafari] = useState(false);

    useEffect(() => {
        expandable && setShowMore(false);
    }, [expandable]);

    useEffect(() => {
        const detectOldSafari = () => {
            const ua = navigator.userAgent;
            const isSafari = /^((?!chrome|android).)*safari/i.test(ua);
            const isOldVersion = /Version\/(15|14|13|12|11|10|9|8)/.test(ua);
            setIsOldSafari(isSafari && isOldVersion);
        };

        detectOldSafari();
    }, []);

    const handleShowBtn = () => {
        if (textRef.current) {
            setShowBtn(textRef.current.scrollHeight > textRef.current.clientHeight);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleShowBtn);
        handleShowBtn();

        return () => {
            window.removeEventListener("resize", handleShowBtn);
        };
    }, [text, html]);

    const handleToggle = () => {
        setShowMore((prev) => !prev);
    };

    const ReadMoreBtn = () => (
        <div className="w-full text-center">
            <span
                className="hover:underline cursor-pointer text-xs text-blue-500"
                onClick={handleToggle}
            >
                {showMore ? "閉じる" : "続きを読む"}
            </span>
        </div>
    );

    return (
        <div {...props}>
            <span
                ref={textRef}
                className="block"
                dangerouslySetInnerHTML={html ? { __html: html } : undefined}
                style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: !expandable ? "unset" : showMore ? "unset" : maxLines,
                    whiteSpace: "normal",

                    // Fallback for older Safari
                    maxHeight: isOldSafari && !showMore ? `${maxLines * lineHeight}em` : "unset",
                }}
            >
               {html ? undefined : text}
            </span>
            {showBtn && <ReadMoreBtn />}
        </div>
    );
}
