import React, { useEffect, useRef, useState, useMemo, useContext } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Contactitem from "../components/Contactitem_1";
import Modal from "../components/Newfoldermodal";

import { toast } from "react-toastify";
import { useAuthContext } from "../auth/context";

import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
} from "tw-elements-react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Apis } from "../api";
import AlarmPart from "../components/AlarmPart";
import FolderItem from "../components/FolderItem";
import PublicView from "../components/PublicView";
import PublicViewSocial from "../components/PublicViewSocial";
import { formatCurrency } from "../utilities/helper";
import { AppContext } from "../App";

export default function ContactList() {
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [profileData, setProfileData] = useState({
        bgColor: "",
        bgURL: "",
        wordColor: "",
        cardColor: "",
        cardURL: "",
        url_name: "",
        faceImg: "",
        realName: "",
        company_url: "",
        companyName: "",
        position: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
        mailAddress: "",
        address: "",
        idCard: {
            idCard: [],
        },
        socialLink: {
            socialLink: [],
        },
        onlineCard_Data: {
            imgLink1: [],
            imgLink2: [],
            imgLink3: [],
            imgLink4: [],
            videoLink: [],
            textLink: [],
            mapLink: [],
            selfProfile: [],
            slideLink: [],
            spaceAdd: [],
        },
    });

    const defaultFolder = [
        { title: "Business", id: 0, count: 0, hasNotice: false },
        { title: "Private", id: 1, count: 0, hasNotice: false },
    ];

    const hasMounted = useRef(false);
    const [navTab, setNavTab] = useState(defaultFolder);
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const tab = useMemo(() => param.get("tab"), [param]);
    const { user, setLoading, reAuth } = useAuthContext();
    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const [isActive, setIsActive] = useState(0);
    const [isBusiness, setIsBusiness] = useState(true);
    const [isShowModal, setIsShowModal] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [modalType, setModalType] = useState("");
    const [contactList, setContactList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [keySearch, setKeySearch] = useState();
    const [notifications, setNotifications] = useState([]);
    const [totalContact, setTotalContact] = useState(0);
    const [totalContactUtilYesterday, setTotalContactUtilYesterday] =
        useState(0);

    const [activeIndex, setActiveIndex] = useState(0);
    const [selectContactUsername, setSelectContactUsername] = useState();

    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (!hasMounted.current) {
            getContactGroupData(tab ? tab : "Business");
            getDataFromDatabase(tab ? tab : "Business", keySearch);
            hasMounted.current = true;
        }
    }, []);

    const getNotification = async () => {
        try {
            const res = await Apis.myGet(`notification/online`);
            if (res?.data) {
                setNotifications(res?.data);
                return;
            }
            setNotifications([]);
        } catch (error) {
            console.error(error);
        }
    };

    const handleReadNotification = async (id) => {
        try {
            await Apis.myPut(`notification/online`, {
                notification_id: id,
                isRead: true,
            });
            await getNotification();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (user) {
            getNotification();
        }
    }, [user]);

    const getContactGroupData = async (title) => {
        try {
            const response = await Apis.myGet(`social/private/contactgroup`);
            let updatedFolder = [...defaultFolder];
            if (response.data) {
                updatedFolder[0].count = response.data.total_business;
                updatedFolder[1].count = response.data.total_private;
                for (const item in response.data.group_data) {
                    response.data.group_data[item].group_Name !== "Business" &&
                        response.data.group_data[item].group_Name !==
                            "Private" &&
                        updatedFolder.push({
                            title: response.data.group_data[item].group_Name,
                            // id: updatedFolder.length,
                            id: response.data.group_data[item].id,
                            count: response.data.group_data[item].count,
                            hasNotice:
                                response.data.group_data[item]
                                    .has_new_contacts ||
                                response.data.group_data[item]
                                    .has_updated_profile_contacts,
                        });

                    if (
                        ["Business", "Private"].includes(
                            response.data.group_data[item].group_Name
                        )
                    ) {
                        updatedFolder = updatedFolder.map((folder) => {
                            if (
                                folder.title ===
                                response.data.group_data[item].group_Name
                            ) {
                                return {
                                    ...folder,
                                    hasNotice:
                                        response.data.group_data[item]
                                            .has_new_contacts ||
                                        response.data.group_data[item]
                                            .has_updated_profile_contacts,
                                };
                            }
                            return folder;
                        });
                    }
                }
                const index = updatedFolder.findIndex(
                    (item) => item.title === title
                );

                setNavTab(updatedFolder);
                setActiveIndex(index === -1 ? 0 : index);
                setIsActive(updatedFolder.at(index === -1 ? 0 : index).id);
                setTotalContact(response.data.total_contact);
                setTotalContactUtilYesterday(
                    response.data.total_contact_until_yesterday
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleFolder = async (folderName) => {
        const folders = [...navTab];
        if (folderName.type === "create") {
            try {
                setLoading(true);
                if (
                    folderName.content !== navTab[activeIndex].title &&
                    navTab.some((tab) => tab.title === folderName.content)
                ) {
                    return toast.error("グループ名が既に存在します");
                }
                const response = await Apis.myPut(
                    `social/private/contactgroup`,
                    {
                        group_Name: folderName.content,
                    }
                );
                if (response.success) {
                    folders.push({
                        title: folderName.content,
                        id: folders[folders.length - 1].id + 1,
                    });
                    setNavTab(folders);
                    toast.success("フォルダーが作成されました");
                    return;
                }
                throw new Error("フォルダーの作成に失敗しました");
            } catch (err) {
                console.error(err);
                toast.error("フォルダーの作成に失敗しました");
            } finally {
                setLoading(false);
            }
        } else if (folderName.type === "modify") {
            try {
                setLoading(true);
                if (
                    folderName.content !== navTab[activeIndex].title &&
                    navTab.some((tab) => tab.title === folderName.content)
                ) {
                    return toast.error("グループ名が既に存在します");
                }
                const response = await Apis.myPut(
                    `social/private/contactgroup?old_group=${folders[activeIndex].title}`,
                    { group_Name: folderName.content }
                );
                if (response.success) {
                    folders[activeIndex].title = folderName.content;
                    setNavTab(folders);
                    toast.success("フォルダー名が変更されました");
                    return;
                }
                throw new Error("フォルダー名の変更に失敗しました");
            } catch (err) {
                toast.error("フォルダー名の変更に失敗しました");
                console.error(err);
            } finally {
                setLoading(false);
            }
        } else if (folderName.type === "delete") {
            if (
                folders[activeIndex].title === "Business" ||
                folders[activeIndex].title === "Private"
            )
                toast.error("このフォルダーはデフォルトです。削除できません。");
            else {
                try {
                    setLoading(true);
                    const response = await Apis.myDelete(
                        `social/private/contactgroup?group_Name=${folders[activeIndex].title}`
                    );
                    if (response.success) {
                        const updatedData = folders.filter(
                            (element) =>
                                folders.indexOf(element) !== activeIndex
                        );
                        setIsActive(0);
                        setNavTab(updatedData);
                        toast.success("フォルダーが削除されました");
                        return;
                    }
                    throw new Error("フォルダーの削除に失敗しました");
                } catch (err) {
                    toast.error("フォルダーの削除に失敗しました");
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const getDataFromDatabase = async (groupName, keySearch = "") => {
        try {
            const searchParams = new URLSearchParams();
            searchParams.append(
                "group_Name",
                groupName || navTab[activeIndex].title
            );
            if (keySearch) {
                searchParams.append("key_search", keySearch);
                !groupName && searchParams.delete("group_Name");
            }
            const url = `social/private/contactdata?${searchParams.toString()}`;
            const response = await Apis.myGet(url, {
                headers: {
                    Authorization: `token ${localStorage.getItem("token")}`,
                },
            });
            if (!groupName) {
                let isNotFound = true;
                for (const tab of navTab) {
                    const tabContacts = response.data.filter(
                        (contact) => contact.group_Name === tab.title
                    );
                    if (tabContacts.length) {
                        setIsActive(tab.id);
                        setContactList(tabContacts);
                        showActiveFolder(tab.id);
                        isNotFound = false;
                        break;
                    }
                }
                if (isNotFound) {
                    showActiveFolder(0);
                    setIsActive(0);
                    setContactList([]);
                }
            } else {
                setContactList(response.data);
            }

            return response.data;
        } catch (error) {
            setContactList([]);
            console.error(error.message);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearch = (e) => {
        setKeySearch(e.target.value);
    };

    const handleRemoveSearch = () => {
        setKeySearch("");
        // setCurrentPage(0);
        showActiveFolder(0);
        setIsActive(0);
    };

    useEffect(() => {
        // Debounce search
        const timeout = setTimeout(() => {
            const tabName = tab && !keySearch ? tab : "";
            getDataFromDatabase(tabName, keySearch);
        }, 700);

        return () => {
            clearTimeout(timeout);
        };
    }, [keySearch]);

    const handleMove = async (username, folder, contact_type) => {
        try {
            await Apis.myPut(`social/private/contactdata`, {
                member: username,
                group_Name: folder,
                contact_type,
            });
            await getContactGroupData(navTab[activeIndex].title);
            await getDataFromDatabase(navTab[activeIndex].title, keySearch);
        } catch (err) {
            console.error(err);
        }
    };

    const handleSwitchFolder = async (firstFolder, secondFolder) => {
        try {
            await Apis.myPost(`social/private/contactgroup`, {
                update_type: "CHANGE_ORDER",
                first_id: firstFolder.id,
                second_id: secondFolder.id,
            });
            await getContactGroupData(navTab[activeIndex].title);
            await getDataFromDatabase(navTab[activeIndex].title, keySearch);
        } catch (err) {
            console.error(err);
        }
    };

    // scroll behavior hand
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 3; // Tốc độ cuộn
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const showActiveFolder = (current) => {
        if (current !== null && scrollRef.current) {
            // Get the active item's position relative to the container
            const activeItem = scrollRef.current.querySelector(
                `li:nth-child(${current + 1})`
            );
            if (activeItem) {
                const container = scrollRef.current;
                const containerWidth = container.offsetWidth;
                const itemOffsetLeft = activeItem.offsetLeft;
                const itemWidth = activeItem.offsetWidth;

                // Calculate the scroll position to make the active item and its page visible
                const pageIndex = Math.floor(
                    activeItem.offsetLeft / containerWidth
                );
                let targetScrollLeft = pageIndex * containerWidth;

                // Adjust the target scroll position to ensure the full item is visible
                const targetItemEnd = itemOffsetLeft + itemWidth;
                const targetPageEnd = targetScrollLeft + containerWidth;

                // If the active item is partially visible on the right side of the target page
                if (targetItemEnd > targetPageEnd) {
                    targetScrollLeft = targetItemEnd - containerWidth;
                }

                // If the active item is partially visible on the left side of the target page
                if (itemOffsetLeft < targetScrollLeft) {
                    targetScrollLeft = itemOffsetLeft;
                }

                // Scroll the container to the calculated position
                container.scrollTo({
                    left: targetScrollLeft,
                    behavior: "smooth", // Optional: Add smooth scrolling for a better user experience
                });

                // Scroll the container to the calculated position
                container.scrollLeft = targetScrollLeft;
            }
        }
    };
    const handleChangeMemo = (data) => {
        const prevContactList = [...contactList];
        const index = prevContactList.findIndex((item) => item.id === data.id);
        prevContactList[index].memo = data.memo;
        prevContactList[index].memo_last_update = data.last_updated;
        setContactList(prevContactList);
    };

    const handleClickTab = async (tabIndex) => {
        setIsActive(navTab[tabIndex].id);
        setActiveIndex(tabIndex);

        const groupName = navTab[tabIndex].title;

        const url =
            groupName === "Business"
                ? "/d-contact"
                : `/d-contact?tab=${groupName}`;

        navigate(url, {
            replace: true,
        });

        await getDataFromDatabase(groupName, keySearch);

        if (navTab[tabIndex].hasNotice) {
            try {
                await Apis.myPost(`social/private/contactgroup`, {
                    group_Name: navTab[tabIndex].title,
                    has_new_contacts: false,
                    has_updated_profile_contacts: false,
                });
                reAuth();
                navTab[tabIndex].hasNotice = false;
            } catch (err) {
                console.error(err);
            }
        }
    };

    const { previousPage } = useContext(AppContext);

    return (
        <>
            <div className="flex justify-between items-center">
                <div>
                    {previousPage /* window.history.length > 2 */ && (
                        <Link to={-1}>
                            <img
                                src="/image/turn-left.png"
                                alt="return"
                                className="w-[40px] h-[60px] cursor-pointer ml-5 pt-5"
                            ></img>
                        </Link>
                    )}
                </div>
                <div className="flex mr-5 pt-5 relative">
                    <AlarmPart
                        data={notifications}
                        onChange={handleReadNotification}
                    />
                    <div className="downBar my-auto" ref={dropdownRef}>
                        <img
                            alt="contactLists"
                            src="/image/contactLists.png"
                            id="contactLists"
                            onClick={toggleDropdown}
                        ></img>
                        {isOpen && (
                            <div className="downList mt-[10px]">
                                <div className="unannouncedList list">
                                    <Link
                                        className="listLink"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setIsOpen(false);
                                            setFolderName("");
                                            setModalType("create");
                                        }}
                                    >
                                        フォルダー作成
                                    </Link>
                                </div>
                                <div className="blockList list">
                                    <Link
                                        className="listLink"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setFolderName(
                                                navTab[activeIndex].title
                                            );
                                            setModalType("modify");
                                            setIsOpen(false);
                                        }}
                                    >
                                        フォルダー名修正
                                    </Link>
                                </div>
                                <div className="deleteList list">
                                    <Link
                                        className="listLink"
                                        onClick={() => {
                                            setIsShowModal(true);
                                            setFolderName(
                                                navTab[activeIndex].title
                                            );
                                            setModalType("delete");
                                            setIsOpen(false);
                                        }}
                                    >
                                        フォルダー削除
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-[95%] md:w-[80%] mx-auto">
                <div className="flex gap-3 items-center md:mt-[50px] ">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                        autoComplete="off"
                        className="w-[calc(100%-60px)] md:w-[560px] flex mx-auto bg-zinc-300 items-center justify-center h-[40px] button px-3"
                    >
                        {/* <span className="w-[calc(100%-60px)] md:w-[560px] flex mx-auto bg-zinc-300 items-center justify-center h-[40px] button px-3"> */}
                        <div
                            id="searchIcon"
                            className="flex justify-center items-center"
                        >
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.0635 18.9371C14.9388 20.5885 12.2648 21.3676 9.58605 21.1157C6.90731 20.8638 4.42524 19.5999 2.64524 17.5813C0.865234 15.5627 -0.0788693 12.9412 0.00516452 10.2506C0.0891983 7.55999 1.19505 5.0026 3.09755 3.09913C5.00005 1.19566 7.55615 0.0892437 10.2454 0.00516714C12.9346 -0.0789094 15.5548 0.865674 17.5723 2.64658C19.5899 4.4275 20.8532 6.91082 21.105 9.59092C21.3567 12.271 20.5781 14.9464 18.9275 17.0722L26 24.1219L24.1228 26L17.0768 18.9371H17.0635ZM10.5859 18.5139C11.6276 18.5139 12.659 18.3086 13.6213 17.9098C14.5836 17.511 15.458 16.9265 16.1945 16.1896C16.9311 15.4526 17.5153 14.5778 17.9139 13.615C18.3125 12.6522 18.5177 11.6202 18.5177 10.5781C18.5177 9.53595 18.3125 8.50401 17.9139 7.5412C17.5153 6.57838 16.9311 5.70355 16.1945 4.96664C15.458 4.22973 14.5836 3.64518 13.6213 3.24637C12.659 2.84756 11.6276 2.6423 10.5859 2.6423C8.4823 2.6423 6.46483 3.47839 4.97733 4.96664C3.48984 6.45489 2.65417 8.47339 2.65417 10.5781C2.65417 12.6828 3.48984 14.7013 4.97733 16.1896C6.46483 17.6778 8.4823 18.5139 10.5859 18.5139Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div id="stick" className="mx-2"></div>
                        <input
                            type="text"
                            className="searchInput w-full pr-2"
                            placeholder="検索"
                            onChange={handleSearch}
                            value={keySearch}
                            autoComplete="off"
                        ></input>
                        {keySearch && (
                            <div
                                className="h-[22px] w-auto"
                                onClick={handleRemoveSearch}
                            >
                                <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11 0C4.917 0 0 4.917 0 11C0 17.083 4.917 22 11 22C17.083 22 22 17.083 22 11C22 4.917 17.083 0 11 0ZM16.5 14.949L14.949 16.5L11 12.551L7.051 16.5L5.5 14.949L9.449 11L5.5 7.051L7.051 5.5L11 9.449L14.949 5.5L16.5 7.051L12.551 11L16.5 14.949Z"
                                        fill="#F8F8F8"
                                    />
                                </svg>
                            </div>
                        )}
                        {/* </span> */}
                    </form>
                    <button
                        className="w-[40px] h-[40px] bg-sky-500 flex items-center justify-center rounded-full"
                        onClick={() => {
                            setIsShowModal(true);
                            setIsOpen(false);
                            setFolderName("");
                            setModalType("create");
                        }}
                    >
                        <svg
                            width="20"
                            height="19"
                            viewBox="0 0 20 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <line y1="9.5" x2="20" y2="9.5" stroke="white" />
                            <line x1="10.5" x2="10.5" y2="19" stroke="white" />
                        </svg>
                    </button>
                </div>
                <div className="flex justify-center items-center gap-2 mb-[20px] md:mb-[35px]">
                    <p>
                        <span className="text-lg">{`${formatCurrency(
                            totalContact
                        )}`}</span>
                        <span className="text-sm">名</span>
                    </p>
                    <p>
                        <span className="text-sm">前日比</span>
                        {totalContact - totalContactUtilYesterday < 0 ? (
                            <span className="text-red-400 text-lg">{` -${
                                totalContactUtilYesterday - totalContact
                            }`}</span>
                        ) : (
                            <span className="text-cyan-400 text-lg">{` +${
                                totalContact - totalContactUtilYesterday
                            }`}</span>
                        )}
                    </p>
                </div>

                <DndProvider backend={HTML5Backend}>
                    <div
                        className="relative px-[22px] md:px-[42px] w-full overflow-x-auto hidden-scroll no-select py-3"
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                    >
                        <DndProvider backend={HTML5Backend}>
                            <ul className="w-fit justify-center flex gap-4 pb-5 overflow-hidden">
                                {navTab.map((item, index) => (
                                    <FolderItem
                                        key={index}
                                        item={item}
                                        index={index}
                                        currentActive={activeIndex}
                                        onClickTab={handleClickTab}
                                        // currentPage={currentPage}
                                        // itemsPerPage={itemsPerPage}
                                        onMove={handleMove}
                                        hasNotice={item.hasNotice}
                                        onSwitchFolder={handleSwitchFolder}
                                    />
                                ))}
                            </ul>
                        </DndProvider>
                        {/* {totalPages > 1 && (
                            <div>
                                <button
                                    className="absolute top-1/2 left-0"
                                    onClick={handlePrevPage}
                                >
                                    <img
                                        src="/image/left-arrow.png"
                                        width="15"
                                        height="15"
                                        alt="left"
                                    />
                                </button>
                                <button
                                    className="absolute top-1/2 right-0"
                                    onClick={handleNextPage}
                                >
                                    <img
                                        src="/image/right-arrow.png"
                                        width="15"
                                        height="15"
                                        alt="right"
                                    />
                                </button>
                            </div>
                        )} */}
                    </div>
                    <ul className="px-2 py-6 md:p-6 divide-y divide-sky-700">
                        {contactList.length > 0 &&
                            contactList.map((person, index) => (
                                <Contactitem
                                    key={index}
                                    url={
                                        person.member_avatar ||
                                        "/image/user_default.png"
                                    }
                                    person={person}
                                    is_new={
                                        person.is_new ||
                                        person.is_profile_update === "True" ||
                                        false
                                    }
                                    isEnableChat={person.is_chat_available}
                                    id={activeIndex}
                                    navTab={navTab}
                                    previewProfile={(data) => {
                                        if (data) {
                                            setProfileData(data);
                                            setShowPreviewModal(true);
                                            setSelectContactUsername(
                                                person.member
                                            );
                                        } else {
                                            toast.error(
                                                "プロフィールデータが存在しません"
                                            );
                                        }
                                    }}
                                    setIsBusiness={setIsBusiness}
                                    onReRender={async () => {
                                        await getContactGroupData(
                                            tab ? tab : "Business"
                                        );
                                        await getDataFromDatabase(
                                            navTab[activeIndex].title,
                                            keySearch
                                        );
                                    }}
                                    currentActive={activeIndex}
                                    onChangeMemo={handleChangeMemo}
                                />
                            ))}
                    </ul>
                    {contactList.length === 0 && (
                        <div className="text-center">データなし</div>
                    )}
                </DndProvider>
                <Modal
                    onChangeName={handleFolder}
                    isShow={isShowModal}
                    setIsShow={setIsShowModal}
                    type={modalType}
                    folder={navTab[activeIndex].title}
                />
            </div>
            <TEModal show={showPreviewModal} setShow={setShowPreviewModal}>
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: "700px",
                        height: "90%",
                        maxHeight: "1000px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader
                            style={{
                                backgroundColor: "#38bdf8",
                                height: "50px",
                            }}
                        >
                            <h1 className="text-2xl p-3 text-white">
                                プロフィールを見る
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setShowPreviewModal(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                paddingRight: 0,
                                paddingLeft: 0,
                            }}
                        >
                            <div style={{ width: "100%" }}>
                                {isBusiness ? (
                                    <PublicView
                                        publishData={profileData}
                                        username={selectContactUsername}
                                    />
                                ) : (
                                    <PublicViewSocial
                                        publishData={profileData}
                                    />
                                )}
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </>
    );
}
