import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Apis, urlAPI } from "../api";
import Emailmarketing from "../pages/admin/Emailmarketing";

const UserListTable = ({ data, changePaging, total, search, onGetUsers }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedValue, setSelectedValue] = useState(100);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [info, setInfo] = useState();
    const [emails, setEmails] = useState([]);

    const handleSelectChange = (event) => {
        setSelectedValue(parseInt(event.target.value));
        setCurrentPage(1);
    };

    const onSelectItem = (id, checked) => {
        if (selectAll) {
            setSelectAll(false);
            setSelectedIds([id]);
        } else {
            if (checked) {
                setSelectedIds([...selectedIds, id]);
            } else {
                setSelectedIds(selectedIds.filter((s) => s !== id));
            }
        }
    };

    const [openDeleteUser, setOpenDeleteUser] = useState(false);
    const [openModalEditUser, setOpenInfoProfile] = useState(false);
    const [openModalSendMail, setOpenModalSendMail] = useState(false);
    const handleCloseModalDeleteUser = () => {
        setOpenDeleteUser(false);
        setInfo(undefined);
    };
    const handleCloseModalEditUser = () => {
        setOpenInfoProfile(false);
        setInfo(undefined);
    };

    const handleCloseModalSendMail = () => setOpenModalSendMail(false);

    const handleCloseModalSendMailAfterSend = () => {
        setOpenModalSendMail(false);
        setEmails([]);
        setSelectAll(false);
        setSelectedIds([]);
    };

    const handleOpenModalSendMail = () => {
        setOpenModalSendMail(true);
    };

    const handleOpenDeleteUser = (item) => {
        setInfo(item);
        setOpenDeleteUser(true);
    };

    const handleDeleteUser = async () => {
        try {
            const response = await Apis.myDelete(
                `${urlAPI.userDetail}?id=${info.userId}`
            );
            if (response.success) {
                toast.success("ユーザーを削除しました");
                onGetUsers();
                return;
            }
            throw new Error("このユーザーの削除中にエラーが発生しました");
        } catch (ex) {
            toast.error(ex.message);
            console.error(ex);
        } finally {
            setInfo(undefined);
            setOpenDeleteUser(false);
        }
    };

    const handleOpenInfoProfile = (item) => {
        setInfo(item);
        setOpenInfoProfile(true);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "55%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "1px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    useEffect(() => {
        if (changePaging) changePaging(selectedValue, currentPage);
    }, [selectedValue, currentPage]);
    useEffect(() => {
        if (selectAll) {
            const getAllEmails = async () => {
                try {
                    const res = await Apis.myGet(
                        `${urlAPI.accountUsers}?user_email=true&keySearch=${search}`
                    );
                    if (res.success) {
                        setEmails(res.data.data);
                        return;
                    }
                    throw new Error("Error");
                } catch (error) {
                    console.error(error);
                }
            };
            getAllEmails();
        } else if (selectedIds) {
            setEmails(
                data
                    .filter((item) => selectedIds.some((i) => i === item.id))
                    .map((item) => item.email)
            );
        }
    }, [selectedIds, selectAll]);

    useEffect(() => {
        setCurrentPage(1);
        setSelectAll(false);
        setSelectedIds([]);
    }, [search]);

    // Render the table
    return (
        <div className="shadow-xl rounded-lg overflow-x-auto">
            <table className="w-full text-center">
                <tbody>
                    <tr className=" bg-[#F7F9FD] !text-left">
                        <th className="pt-10 pb-2 pl-3">No</th>
                        <th className="pt-10 pb-2 pl-3 max-w-[200px]">氏名</th>
                        <th className="pt-10 pb-2 pl-3">ユーザーID</th>
                        <th className="pt-10 pb-2 pl-3">名刺URL</th>
                        <th className="pt-10 pb-2 pl-3">新規登録日時</th>
                        <th className="pt-10 pb-2 pl-3">紹介した人ID</th>
                        <th className="pt-10 pb-2">
                            <div className=" flex gap-2 pl-2 items-end">
                                <div>
                                    {selectedIds.length > 0 || selectAll ? (
                                        <img
                                            alt="mail"
                                            onClick={handleOpenModalSendMail}
                                            src="/image/icon-email-send.svg"
                                            width="21"
                                            className="mx-1 !w-5 cursor-pointer"
                                        />
                                    ) : (
                                        <img
                                            alt="mail"
                                            src="/image/email.png"
                                            width="21"
                                            className="mx-1 !w-5"
                                        />
                                    )}
                                </div>
                                <div
                                    className="cursor-pointer text-[#4C31F4]"
                                    onClick={() => setSelectAll(!selectAll)}
                                >
                                    すべて
                                </div>
                            </div>
                        </th>
                        <th className="py-3"></th>
                    </tr>
                    {data?.map((item, index) => (
                        <tr
                            key={index}
                            className={`even:bg-white odd:bg-[#EBF2FF] !text-start`}
                        >
                            <td className="p-2 pl-3">{item.id}</td>
                            <td className="p-2 pl-3 min-w-[150px] max-w-[200px]">
                                <p className="break-all line-clamp-1">
                                    {item.full_name}
                                </p>
                            </td>
                            <td className="p-2  pl-3 min-w-[300px] max-w-[400px]">
                                <p className="break-all line-clamp-1">
                                    {item.username}
                                </p>
                            </td>
                            <td className="p-2  pl-3 max-w-[450px]">
                                {item.url_name_business && (
                                    <p>{`https://degime.net/${item.url_name_business}`}</p>
                                )}
                                {item.url_name_sns && (
                                    <p>{`https://degime.net/${item.url_name_sns}`}</p>
                                )}
                            </td>
                            <td className="p-2  pl-3">
                                {item.registerDate.split("T")[0]}
                            </td>
                            <td className="p-2  pl-3 min-w-[150px] max-w-[200px]">
                                <p className="break-all line-clamp-1">
                                    {item.refer}
                                </p>
                            </td>
                            <td className="p-2 pl-3">
                                <div className=" w-full flex justify-start pl-1">
                                    <input
                                        type="checkbox"
                                        onChange={(event) =>
                                            onSelectItem(
                                                item.id,
                                                event.target.checked
                                            )
                                        }
                                        name="select_for_mail"
                                        disabled={!item.is_email_delivery}
                                        checked={
                                            selectAll ||
                                            selectedIds.includes(item.id)
                                        }
                                    />
                                </div>
                            </td>
                            <td className="py-2 px-4">
                                <div className="flex flex-wrap w-10 gap-2 justify-center items-center">
                                    <Button
                                        onClick={() =>
                                            handleOpenDeleteUser(item)
                                        }
                                        to="/shop/detail"
                                        className="!rounded-lg !text-white cursor-pointer !bg-red-400 hover:bg-red-500 !p-0 active:bg-red-600 !text-sm px-1 md:px-4"
                                    >
                                        削除
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            handleOpenInfoProfile(item)
                                        }
                                        className="!rounded-lg !p-0 cursor-pointer !text-white !bg-lime-400 hover:bg-lime-500 active:bg-lime-600 !text-sm px-1 md:px-4"
                                    >
                                        編集
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Render the pagination controls here */}
            <div className="w-full  min-w-[900px] p-3 flex justify-around items-center bg-white !text-slate-400">
                <div className=" w-24">{`${
                    total - (currentPage * selectedValue - selectedValue)
                }-${
                    currentPage * selectedValue > total
                        ? 1
                        : total - currentPage * selectedValue + 1
                } of ${total}`}</div>
                <div className="w-full justify-center flex gap-2">
                    <div>
                        <span className=" text-slate-400 hidden md:inline-block">
                            ページあたりの行数：
                        </span>
                        <select
                            className=" border-none"
                            value={selectedValue}
                            onChange={handleSelectChange}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                            disabled={currentPage === 1}
                            className="mr-2 max-[400px]:ml-2 ml-10"
                        >
                            <svg
                                width="14"
                                height="23"
                                viewBox="0 0 14 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 22L1 11.5L13 1"
                                    stroke="#868FA0"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <span className="max-[500px]:text-sm max-w-[70px] !text-black">
                            {currentPage} /{" "}
                            {Math.ceil((total || data?.length) / selectedValue)}
                        </span>
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={
                                currentPage ===
                                    Math.ceil(
                                        (total || data?.length) / selectedValue
                                    ) ||
                                !data ||
                                data?.length === 0
                            }
                            className="mx-2"
                        >
                            <svg
                                width="14"
                                height="23"
                                viewBox="0 0 14 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L13 11.5L1 22"
                                    stroke="#868FA0"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={openDeleteUser}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style }} className="min-w-[900px]">
                    <h2
                        className="text-zinc-600 text-center font-bold text-[24px] mt-2"
                        id="modal-title"
                    >
                        ユーザーを削除しますがよろしいですか?
                    </h2>
                    {/* <p id="modal-description">
                        アカウント削除は、ユーザー情報と関連データをシステムから永久に削除するプロセスです。削除後、ユーザーはアカウントにアクセスできなくなり、すべてのデータが失われます。この操作は元に戻せないため、実行前に確認してください。
                    </p> */}
                    <div className="flex justify-between max-[530px]:mt-[20px] mt-[70px] font-light px-6">
                        <button
                            className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                            onClick={handleDeleteUser}
                        >
                            はい
                        </button>
                        <button
                            className="text-white font-bold border py-1 px-6 rounded-xl bg-danger-600 hover:bg-danger-700"
                            onClick={handleCloseModalDeleteUser}
                        >
                            いいえ
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openModalEditUser}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className=" w-full"
            >
                <Box
                    sx={{ ...style }}
                    className=" w-3/4 max-h-screen overflow-auto rounded-xl !p-10"
                >
                    <div className="w-full flex justify-end gap-10">
                        <Button
                            className=" !bg-[#FF6A68] rounded-xl !text-white !font-bold !w-52 !h-16"
                            onClick={handleCloseModalEditUser}
                        >
                            戻る
                        </Button>
                    </div>
                    <div className="font-light">
                        <label className=" p-5 font-bold text-black">
                            ユーザー名
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.username || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className=" p-5 font-bold text-black">
                            ユーザーID
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.userId || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className=" p-5 font-bold text-black">
                            メールアドレス
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.email || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className="  p-5 font-bold text-black">
                            名刺URL
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.url || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className="  p-5 font-bold text-black">
                            新規登録日時
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={new Date(info?.registerDate || "")}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                        <label className="  p-5 font-bold text-black">
                            紹介した人ID
                        </label>
                        <div className="flex items-center">
                            <input
                                type="text"
                                value={info?.refer_id || ""}
                                disabled
                                className="w-full h-16 rounded-xl px-4 focus:border-secondary-200 text-black peer-[1]: placeholder-slate-900 placeholder:font-medium !border-2"
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openModalSendMail}
                onClose={handleCloseModalSendMail}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                disableEnforceFocus
            >
                <Box
                    sx={{ ...style }}
                    className="min-w-[900px] !h-screen overflow-auto"
                >
                    <Emailmarketing
                        emails={emails}
                        onSend={handleCloseModalSendMailAfterSend}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default UserListTable;
