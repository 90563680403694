import { Close } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Apis } from "../api";

const AlarmPart = ({ data, onChange }) => {
    const [open, setIsOpen] = useState(false);
    const [showBadge, setShowBadge] = useState(false);

    useEffect(() => {
        setShowBadge(data.length !== 0);
    }, [data]);

    const handleClickItem = (item) => {
        onChange(item.id);
        setIsOpen(false);
    };

    const handleClickNotificationBell = async () => {
        setIsOpen((prevState) => !prevState);
        setShowBadge(false);
        try {
            await Apis.myPost("notification/read_all_notification", {});
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="downBar relative h-5">
            <button className="relative " onClick={handleClickNotificationBell}>
                <img
                    alt="alarm"
                    src="/image/alarm.png"
                    id="alarm"
                    className="cursor-pointer"
                ></img>
                {showBadge && <div className="alarmBadge"></div>}
            </button>
            <Modal open={open} onClose={() => setIsOpen(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 2,
                        maxHeight: "75%",
                    }}
                >
                    <div className="flex justify-end cursor-pointer ">
                        <Close
                            onClick={() => setIsOpen(false)}
                            className="text-zinc-500"
                        />
                    </div>
                    {open &&
                        (data.length === 0 ? (
                            <div className="list px-4 pb-4">
                                <div className="listLink">通知はありません</div>
                            </div>
                        ) : (
                            <div className="overflow-y-auto px-4 pb-4 max-h-[400px] hidden-scroll w-full md:w-auto">
                                {data.map((item, index) => (
                                    <div key={index} className="list text-left">
                                        {item.redirect_url === "" ? (
                                            <div
                                                className="listLink cursor-pointer"
                                                onClick={() =>
                                                    handleClickItem(item)
                                                }
                                            >
                                                {item.content
                                                    .split("\n")
                                                    .map((line, i) => (
                                                        <div key={i}>
                                                            {line}
                                                        </div>
                                                    ))}
                                            </div>
                                        ) : (
                                            <Link
                                                className="listLink"
                                                target="_blank"
                                                to={item.redirect_url}
                                                onClick={() =>
                                                    handleClickItem(item)
                                                }
                                            >
                                                {item.content
                                                    .split("\n")
                                                    .map((line, i) => (
                                                        <div key={i}>
                                                            {line}
                                                        </div>
                                                    ))}
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                </Box>
            </Modal>
        </div>
    );
};

export default AlarmPart;
