import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import MemoComponent from "./Memocomponent";

import { Box, Modal } from "@mui/material";
import { useDrag } from "react-dnd";
import { toast } from "react-toastify";
import { TERipple } from "tw-elements-react";
import { Apis } from "../api";
import { useAuthContext } from "../auth/context";
import PersonDescription from "./PersonDescription";

export default function Contactitem({
    url,
    navTab,
    person,
    is_new,
    id,
    isEnableChat,
    onReRender,
    previewProfile,
    currentActive,
    setIsBusiness,
    onChangeMemo,
}) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isNew, setIsNew] = useState(is_new);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMoveOpen, setIsMoveOpen] = useState(false);
    const [requestState, setRequestState] = useState(0);
    const dropdownRef = useRef(null);
    const modalTitle = [
        "この人にチャットを申請しますか？",
        "プロフィールを見ますか？",
        "非通知にしますがよろしいですか?",
        "ブロックにしますがよろしいですか?",
        "削除にしますがよろしいですか?",
        "この人からの通知を受け取りますか?",
        "この人のブロックを解除しますか?",
    ];
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const { reAuth } = useAuthContext();

    useEffect(() => {
        setIsNew(is_new);
    }, [is_new]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false); // Hide the dropdown
            }
        };
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleMove = async (title) => {
        try {
            setIsMoveOpen(false);
            const response = await Apis.myPut(`social/private/contactdata`, {
                member: person.member,
                group_Name: title,
                contact_type: person.contact_type,
            });
            onReRender(true);
        } catch (err) {
            console.error(err);
        }
    };

    // post contact update request
    const requestParam = ["", "", "Unannounce", "Block", "Deleted", "", ""];

    const handleModalOpen = (num) => {
        setIsOpen(true);
        setIsModalOpen(true);
        setRequestState(num);
    };
    const handleRequest = async () => {
        if (requestState < 7 && requestState > 1) {
            try {
                const response = await Apis.myPut(
                    `social/private/contactdata`,
                    {
                        member: person.member,
                        block_setting: requestParam[requestState],
                    },
                    {
                        headers: {
                            Authorization: `token ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    }
                );
                if (response.success) {
                    onReRender(true);
                }
            } catch (err) {
                console.error(err);
            }
            setIsModalOpen(false);
        } else if (requestState === 0) {
            try {
                const response = await Apis.myPut(
                    `social/private/contactdata`,
                    { member: person.member, is_pending: "addChat" }
                );
                if (response.success) {
                    toast.success(
                        `${person.member}さんにチャートを申請しました。`
                    );
                    navigate("/d-chat/list?tab=申請者");
                    return;
                }
                throw new Error("申請に失敗しました");
            } catch (err) {
                toast.error("申請に失敗しました");
                console.error(err);
            } finally {
                setIsModalOpen(false);
            }
        } else if (requestState === 1) {
            try {
                const BusinessResponse = await Apis.myGet(
                    `social/public/online?username=${encodeURIComponent(
                        person.member
                    )}`
                );
                if (BusinessResponse.data) {
                    previewProfile(BusinessResponse.data[0]);
                    setIsModalOpen(false);
                    setIsBusiness(true);
                    return;
                }
                const response = await Apis.myGet(
                    `social/public/snstree?username=${encodeURIComponent(
                        person.member
                    )}`
                );
                if (response.data) {
                    previewProfile(response.data[0]);
                    setIsModalOpen(false);
                    setIsBusiness(false);
                    return;
                }
                throw new Error("ページが存在しません");
            } catch (error) {
                toast.error("ページが存在しません");
                console.error(error.message);
            }
        } else setIsModalOpen(false);
        setIsOpen(false);
    };

    const [{ isDragging }, drag /* , preview */] = useDrag({
        type: "CONTACT_ITEM",
        item: {
            type: "CONTACT_ITEM",
            username: person.member,
            folder: navTab[currentActive].title,
            contact_type: person.contact_type,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !isMoveOpen && !isOpen && !showMemoPane,
    });

    const opacity = isDragging ? 0 : 1;

    const getTitle = (value) => {
        switch (value) {
            case "Business":
                return "ビジネス";
            case "Private":
                return "プライベート";
            default:
                return value;
        }
    };

    const updateHaveReadProfile = async () => {
        try {
            const isSNSCard = person.contact_type === "SNS";
            const url = `social/public/${isSNSCard ? "snstree" : "online"}`;
            const res = await Apis.myGet(
                `${url}?username=${encodeURIComponent(
                    person.member
                )}&contact_type=${person.contact_type}`
            );
            if (res.data && res.data.length) {
                reAuth();
                setIsNew(false);
                setTimeout(() => {
                    window.open(
                        `${window.origin}/${person.member}${
                            isSNSCard ? "-2" : ""
                        }`,
                        "_blank"
                    );
                }, 0);
                return;
            }
            throw new Error("ページが存在しません!");
        } catch (error) {
            toast.error("ページが存在しません!");
            console.error(error.message);
        }
    };

    const [showMemoPane, setShowMemoPane] = useState(false);

    const userName = () => {
        return (
            person.member_account_name_SNS ||
            person.member_account_name ||
            person.member_full_name ||
            person.member
        );
    };

    return (
        <div style={{ opacity }} ref={(node) => drag(node)}>
            <li className="flex py-4 first:pt-0 last:pb-0 relative items-center">
                <div
                    onClick={updateHaveReadProfile}
                    className="relative cursor-pointer"
                >
                    <div></div>
                    <img
                        className="h-10 w-10 rounded-full bg-white p-1"
                        src={url}
                        alt={`${userName()}-avatar`}
                    />
                    {isNew && (
                        <div className="absolute flex justify-center items-center -top-1 -right-1 bg-[#FFC000] text-white text-xs h-[18px] w-[18px] rounded-full">
                            <span>新</span>
                        </div>
                    )}
                </div>
                <div
                    className="ml-3 overflow-hidden w-[60%] cursor-pointer"
                    onClick={updateHaveReadProfile}
                >
                    <p className="text-sm font-medium text-slate-900">
                        {userName()}
                        {person.contact_type === "SNS" && (
                            <span className="p-1 leading-8 border rounded-md border-[#2ACEFF] bg-[#2ACEFF] text-white ml-2">
                                サブページ
                            </span>
                        )}
                    </p>
                    <PersonDescription person={person} />
                </div>
                <div className="ml-auto" ref={dropdownRef}>
                    <div
                        className="shrink-0 bg-orange-400 text-sm cursor-pointer text-white font-bold py-[5px] mobile:px-3 min-[480px]:px-10 rounded-full hover:bg-orange-500 focus:outline-none focus:shadow-outline transform transition-colors duration-150 self-start my-auto h-[30px] min-w-[52px] text-center"
                        onClick={toggleDropdown}
                    >
                        設定
                    </div>
                    {isOpen && (
                        <div className="downList">
                            {(!isEnableChat || isEnableChat === "False") &&
                                person.is_pending !== "addChat" && (
                                    <div className="recoverList list cursor-pointer">
                                        <div
                                            className="listLink"
                                            onClick={() => {
                                                handleModalOpen(0);
                                            }}
                                        >
                                            チャットを申請する
                                        </div>
                                    </div>
                                )}
                            <div className="previewList list cursor-pointer">
                                <div
                                    className="listLink"
                                    onClick={() => {
                                        handleModalOpen(1);
                                    }}
                                >
                                    プロフィールページを表示
                                </div>
                            </div>
                            <div className="nocontactList list cursor-pointer">
                                <div
                                    className="listLink"
                                    onClick={() => {
                                        handleModalOpen(
                                            person.block_setting ===
                                                "Unannounce"
                                                ? 5
                                                : 2
                                        );
                                    }}
                                >
                                    {person.block_setting === "Unannounce" && (
                                        <span className="text-red-500 pr-2">
                                            ✓
                                        </span>
                                    )}
                                    非通知
                                </div>
                            </div>
                            <div className="blockList list cursor-pointer">
                                <div
                                    className="listLink"
                                    onClick={() => {
                                        handleModalOpen(
                                            person.block_setting === "Block"
                                                ? 6
                                                : 3
                                        );
                                    }}
                                >
                                    {person.block_setting === "Block" && (
                                        <span className="text-red-500 pr-2">
                                            ✓
                                        </span>
                                    )}
                                    ブロック
                                </div>
                            </div>
                            <div className="deletedList list cursor-pointer">
                                <div
                                    className="listLink"
                                    onClick={() => {
                                        handleModalOpen(4);
                                    }}
                                >
                                    削除
                                </div>
                            </div>
                            <div className="moveList list cursor-pointer">
                                <div
                                    className="listLink"
                                    onClick={() => {
                                        setIsMoveOpen(true);
                                        setIsOpen(false);
                                    }}
                                >
                                    別のグループに移動
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="inline-block ml-3">
                    <MemoComponent
                        showMemoPane={showMemoPane}
                        setShowMemoPane={setShowMemoPane}
                        data={person.memo}
                        lastUpdate={person.memo_last_update}
                        id={person.id}
                        onReloadData={onChangeMemo}
                    />
                </div>
            </li>
            <Modal open={isModalOpen} className="mobile:w-full">
                <Box className=" !bg-gray-100 mobile:w-[90%]">
                    <div
                        className="flex justify-between flex-col absolute inset-1/2 transform -translate-x-1/2 top-[50%] left-[50%] -translate-y-1/2 z-50 pc:w-[500px] w-[90%] h-[200px] p-6 bg-white rounded-lg"
                        style={{ border: "solid 3px black" }}
                    >
                        <h2 className="text-zinc-600 text-center font-bold pc:text-[24px] text-base mt-2">
                            {modalTitle[requestState]}
                        </h2>
                        <div className="flex justify-between mobile:mt-[20px] mt-[70px] font-light px-6">
                            <button
                                className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                onClick={handleRequest}
                            >
                                はい
                            </button>
                            <button
                                className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={() => {
                                    setIsModalOpen(false);
                                    setIsOpen(false);
                                }}
                            >
                                いいえ
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* {isModalOpen && (
                <>
                    <div
                        className="fixed grid inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 pc:w-[500px] w-[90%] h-[200px] p-6 bg-white rounded-lg"
                        style={{ border: "solid 3px black" }}
                    >
                        <h2 className="text-zinc-600 text-center font-bold pc:text-[24px] text-[18px] mt-2">
                            {modalTitle[requestState]}
                        </h2>
                        <div className="flex justify-between mobile:mt-5 h-full font-light px-6 items-center">
                            <button
                                className="text-zinc-600 font-bold border h-12 py-1 px-6 rounded-xl"
                                onClick={handleRequest}
                            >
                                はい
                            </button>
                            <button
                                className="text-white font-bold border h-12 py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={() => {
                                    setIsModalOpen(false);
                                    setIsOpen(false);
                                }}
                            >
                                いいえ
                            </button>
                        </div>
                    </div>
                    <div className="fixed w-full h-full right-0 top-0 bg-black opacity-10 z-30 disable-scroll"></div>
                </>
            )} */}
            {isMoveOpen && (
                <div className='absolute after:content-[""] after:block after:clear-both inset-1/2 z-50 w-full'>
                    <div
                        className="transform -translate-x-1/2 -translate-y-1/2  pc:w-[500px] w-[90%] pc:p-6 p-4 bg-white rounded-lg"
                        style={{ border: "solid 3px black" }}
                    >
                        <h2 className="text-zinc-600 text-center font-bold pc:text-[24px] text-[18px] mt-2">
                            別のグループに移動させますか？
                        </h2>
                        <button
                            type="button"
                            className="absolute right-2 top-2 box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={() => setIsMoveOpen(false)}
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <div className="flex flex-col justify-between mobile:mt-[20px] mt-[10px] font-light px-6 z-30">
                            {navTab.map((item, index) => (
                                <TERipple>
                                    {index !== id && (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                handleMove(item.title);
                                            }}
                                            className="w-full cursor-pointer text-center p-2 rounded-full border-2 my-1"
                                        >
                                            {getTitle(item.title)}
                                        </div>
                                    )}
                                </TERipple>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
