import React, { useState, useEffect, useRef } from "react";
import { Close } from "@mui/icons-material";
import { FileUpload } from "../utilities/upload";
import { CircularProgress } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

const ImageSlider = ({
    onChangeData,
    initialData,
    name,
    aspect = "4/3",
    backgroundColor,
    isProfilePage = false,
}) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newImageAdded, setNewImageAdded] = useState(false);
    const swiperRef = useRef(null);
    useEffect(() => {
        if (
            (Array.isArray(initialData) && initialData.length > 0) ||
            typeof initialData === "string"
        ) {
            if (initialData.length > 0) setImages(initialData);
            if (typeof initialData === "string") setImages([initialData]);
        } else {
            setImages(["/image/Carousel.png"]); // 初期状態で /image/Carousel.png を設定
        }
    }, [initialData]);

    const handleAddImage = async (event) => {
        setLoading(true);
        try {
            const uploadedImage = await FileUpload(event.target.files[0]);
            setImages((prevImages) => {
                const newImages = [...prevImages];
                if (newImages[0] === "/image/Carousel.png") {
                    newImages[0] = uploadedImage; // 最初の /image/Carousel.png を置き換える
                } else {
                    newImages.push(uploadedImage); // 新しい画像を追加
                }
                onChangeData(newImages);
                setNewImageAdded(true);
                return newImages;
            });
        } catch (error) {
            console.error("画像のアップロードに失敗しました", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
        onChangeData([...newImages]);
        if (newImages.length === 0) newImages.push("/image/Carousel.png"); // 画像がなくなったら /image/Carousel.png を追加
    };

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper && newImageAdded) {
            swiperRef.current.swiper.slideTo(images.length - 1);
            setNewImageAdded(false);
        }
    }, [images, newImageAdded]);

    return (
        <div className="squareUploader w-full h-full mx-auto">
            <Swiper
                ref={swiperRef}
                modules={[Pagination, Navigation]}
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }}
                navigation
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div
                            className={`relative aspect-[${aspect}] ${backgroundColor} overflow-hidden mx-auto flex items-center justify-center`}
                        >
                            {image === "/image/Carousel.png" ? (
                                <div className="">
                                    <label
                                        htmlFor={`${name}-image-upload-${index}`}
                                        className="cursor-pointer"
                                    >
                                        <img
                                            src={image}
                                            className="max-w-full max-h-full object-contain"
                                            alt=""
                                        />
                                        {onChangeData && (
                                            <input
                                                id={`${name}-image-upload-${index}`}
                                                type="file"
                                                accept="image/*"
                                                className="hidden"
                                                onChange={(event) =>
                                                    handleAddImage(event)
                                                }
                                            />
                                        )}
                                    </label>
                                    {isProfilePage && (
                                        <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center">
                                            <span className="text-[#ACACAE] text-6xl md:text-8xl font-bold">
                                                名刺画像
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="w-full h-full">
                                    <label className="h-full w-full">
                                        <img
                                            src={image}
                                            className="max-w-full max-h-full object-contain"
                                            alt=""
                                            style={{
                                                objectFit: "contain",
                                                backgroundSize: "contain",
                                                aspectRatio: "4/3",
                                                width: "100%",
                                                height: "100%",
                                            }} // ここで object-fit: contain を適用
                                        />
                                        {onChangeData && (
                                            <div className="absolute right-4 top-4 flex items-center">
                                                <Close
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveImage(index)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </label>
                                    {onChangeData && (
                                        <div className="absolute bottom-2 right-1">
                                            <label
                                                htmlFor={`${name}-image-upload-${index}`}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src="/image/uploadImages.png"
                                                    alt="uploadImages"
                                                    className="w-14 h-14"
                                                />

                                                <input
                                                    id={`${name}-image-upload-${index}`}
                                                    type="file"
                                                    accept="image/*"
                                                    className="hidden"
                                                    onChange={(event) =>
                                                        handleAddImage(event)
                                                    }
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                            )}
                            {loading && (
                                <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center z-1000">
                                    <CircularProgress />
                                </div>
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ImageSlider;
